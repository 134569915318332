.dashboard {
  width: 80%;
  margin: 0 auto;
}

.dashboard-header {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 50px 0 20px 0;
  height: auto;
}

.dashboard-header h1 {
  color: #3bce8e;
  margin: 0;
}

.search-div {
  display: flex;
  align-items: center;
}

.searchBar {
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 35px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.stats-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  padding: 40px 0;
}

.stats-box {
  background-color: #f0f0f0;
  border-radius: 10px;
  height: 100px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stats-box h2 {
  margin: 0 0 10px 0;
  color: rgb(59, 206, 142);
  font-size: 30px;
}

.graphs-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px 0 80px 0;
  
}

.graph-box {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;  
}

.graph-box h2{
  padding: 0 0 20px 0;
  color: #3bce8e;
  text-align: center;
}

.graphs-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.mfaBtn{
  background-color: dodgerblue;
}

.mfaBtn:hover{
  background-color: rgb(98, 145, 180);
}

.mfaBtn-on{
  background-color: salmon;
}

.mfaBtn-on:hover{
  background-color: rgb(240, 97, 97);
}