.app-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.page-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.page-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../img/bgSweep.webp) bottom center no-repeat;
  background-size: cover;
  opacity: 0.05;
  z-index: -1;
}

.page-inner-content {
  position: relative;
  z-index: 1;
  overflow-y: auto;
}


.access-denied {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100vh;
  width: 100vw;
  text-align: center;
  color: #3bce8e;
}

.access-denied h1 {
  font-size: 2.5em;
}

.access-denied p {
  font-size: 1.2em;
  margin-bottom: 1em;
}

.access-denied .fa-lock {
  font-size: 3em;
  color: #3bce8e;
}

.loading-screen {
  color: #3bce8e;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.loading-screen p {
  font-size: 2rem;
}

.loading-screen i {
  font-size: 2rem;
}
