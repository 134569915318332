.users-list {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.users-list::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../../img/bgSweep.webp) bottom center no-repeat;
  background-size: cover;
  opacity: 0.05;
  z-index: -1;
}

.users-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  height: 100vh;
}

.users-list-container h1 {
  color: #3bce8e;
  text-align: left;
}

.users-page-container{
  height: auto;/* Colocando a 100% a tabela fica com um tamanho fixo, assim varia com o nº de users */
  width: auto;
}

.user-options {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 70px 0 30px 0;
  height: auto;
}

.searchBar {
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 35px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.users-table{
  position: relative;
  height: 85%;
}

.users-table .statsTable {
  margin: 0 auto;
  background-color: #f0f0f0;
  border-spacing: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  table-layout: auto;
  width: 100%;
}

.users-table .statsTable th,
.users-table .statsTable td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.users-table .statsTable th {
  background-color: #3bce8e;
  border-right: 1px solid white;
  font-weight: bold;
}


.users-table .statsTable tbody tr:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}

.users-table .sortBtn {
  color: white;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  gap: 15px;
  align-items: center;
  text-align: left;
  padding: 5px 0;
}

.statsTable .sortBtn i {
  font-size: 12px;
}

.pagination{
  margin: 0 auto;
  padding: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3bce8e;
}

.pagination button{
  background-color: transparent;
  color: #3bce8e;
}

.pop-up {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3bce8e;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  z-index: 1000;
}


.pop-up.hide {
  display: none;
}

.pop-up.error {
  background-color: #f44336; 
}

.progress-user-page{
  width: 50px;
}