.loading-page{
    height: 60vh;
    padding-top: 40vh;
}

.loading-page-text{
    color:#365f5889;
    font-weight: 500;
 
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 13px;
    text-align: center;
    margin: 50px;
}

@media only screen and (max-width:600px) {
    .loading-page{
        height: 70vh;
        padding-top: 30vh;
    }
}