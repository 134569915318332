/* Styling for .partners-list */
.partners-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-y: scroll;
  padding: 0 50;
}

.partners-list::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../../img/bgSweep.webp) bottom center no-repeat;
  background-size: cover;
  opacity: 0.05;
  z-index: -1;
}

.partners-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
  height: 100vh;
  width: 95%;
  
}

.partners-list-container h1 {
  color: rgb(59, 206, 142);
  text-align: left;
}

.partners-page-container {
  height: auto; /* Colocando a 100% a tabela fica com um tamanho fixo, assim varia com o nº de users */
  width: 100%;
}

.partner-options {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0 30px 0;
  font-size: 12px;
}

.search-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.searchBar {
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 35px;
  text-align: center;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}

.partners-table {
  position: relative;
  padding-bottom: 50;
}

.partners-table .statsTable {
  margin: 0 auto;
  background-color: #f0f0f0;
  border-spacing: 0;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 100%;
  table-layout: auto;
}

.partners-table .statsTable th,
.partners-table .statsTable td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 14px;
}

.partners-table .statsTable th {
  background-color: #3bce8e;
  border-right: 1px solid white;
  font-weight: bold;
  font-size: 14px;
}

.partners-table .statsTable tbody tr:hover {
  background-color: #e6e6e6;
  cursor: pointer;
  font-size: 14px;
}

.partners-table .statsTable .sortBtn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  font-size: 14px;
}

.td-name:hover {
  cursor: pointer;
  color: rgba(59, 206, 142, 1);
}

.partner-image {
  width: 70px;
  height: 70px;
  object-fit: contain;
  transition: transform 0.5s;
}

.partner-image:hover {
  transform: scale(3.25);
}
