

.account-page-container {
  height: auto;
  width: 90%;
  margin: 0 auto;
}

.account-top-bar {
  border-radius: 10px;
  margin: 4rem auto 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: auto;
  background-color: #f0f0f0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.account-profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 15px;
}

.account-image {
  border-radius: 50%;
  border: 2px solid #3bce8e;
  font-size: 3rem;
  color: #3bce8e;
}

.account-image img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  width: 150px;
  height: 150px;
}

.first-letter {
  border-radius: 100px;
  width: 100px;
  height: 100px;
  display: flex;
  place-content: center;
  place-items: center;
}

.first-letter  > h2{
  font-size: 3rem;
  color: #3bce8e;
}


.account-info h1 {
  font-size: 2.5rem;
}
.account-info h2 {
  padding: 10px 0;
  color: #3bce8e;
}

.account-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.edit-account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
}

.account-editBtns {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.editBtns-row .editBtn{
  width: 100%;
}

.account-editBtns .deleteBtn {
  background-color: red;
  display: flex;
  justify-content: center;
}

.account-editBtns .deleteBtn:hover {
  background-color: rgb(208, 0, 0);
  display: flex;
  justify-content: center;
}

.editBtns-row{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: auto;
}

.user-info-grid {
  display: grid;
  padding: 50px 0;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
}

.user-info-box {
  background-color: #f0f0f0;
  border-radius: 10px;
  height: 500px;
  padding: 10px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#communities-box,
#events-box {
  grid-column: span 1;
  grid-row: span 1;
  height: auto;
}

#map-box {
  grid-column: span 2;
  grid-row: span 2;
  padding-bottom: 50px;
}


.small-map-container{
  width: 90%;
  overflow: hidden;
}

.user-info-box h1 {
  color: rgb(59, 206, 142);
  padding: 10px 0 20px 0;
}

.empty-list{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.empty-list img{
  width: 60%;
}

.empty-list p{
  font-size: 1.2rem;
  font-weight: 600;
}


.ul-communities {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding:  0 0 20px 0;
}

.ul-communities li {
  list-style: none;
  background-color: rgba(59, 206, 142, 0.05);
  padding: 10px;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.community-info{
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.cm-flex{
  display: flex;
  gap: 10px;
  align-items: center;
  place-content: center;
}


.community-info > b{
  font-weight: 400;
  color:white;
  background-color: #3bce8e;
  padding:4px;
  border-radius: 8px;
  font-size: 13px;
}


.community-image img{
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 0.5px solid #3bce8e;
}

.community-name{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.community-name i{
  color: red;
  cursor: pointer;
}

.pop-up {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3bce8e;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  z-index: 1000;
}

.pop-up.hide {
  display: none;
}

.pop-up.error {
  background-color: #f44336;
}
