
progress {
    border-radius: 7px; 
    height: 6px;
  }

  progress::-webkit-progress-bar {
    background-color: white;
    border-radius: 7px;
  }
  progress::-webkit-progress-value {
    background-color: #3bce8e;
    border-radius: 7px;
  }
  progress::-moz-progress-bar {
    background-color: white;
    border-radius: 7px;
  }