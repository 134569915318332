.selected-community-list {
  height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.selected-community-list::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../../img/bgSweep.webp) bottom center no-repeat;
  background-size: cover;
  opacity: 0.05;
  z-index: -1;
}

.selected-community-page {
  text-align: center;
  margin: 0 auto;
  width: 80%;
  padding-bottom: 50;
}

.selected-community-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.selected-community-container h1,
h3 {
  color: #3bce8e;
  text-align: left;
  width: 80%;
}

.selected-community-container h3 {
  color: black;
  text-align: left;
  width: 80%;
}

.backBtn-div{
  display: flex;
  justify-content: flex-start;
  margin: 2rem auto 4rem auto;
}

.backBtn-div button{
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: transparent;
  color: #3bce8e;
  font-weight: bold;
}


.selected-community-top-bar {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  padding: 0px 0 40px 0;
  height: 10vh;
}

.selected-community-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: 100px;
}

.selected-community-image img {
  height: 100px;
  width: 100px;
  border-radius: 100%;
}

.selected-community-info h3 {
  padding: 10px 0;
}

.selected-community-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.actions-btns {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.actions-btns .removeBtn {
  background-color: red;
}

.actions-btns .removeBtn:hover {
  background-color: rgb(208, 0, 0);
}

.edit-community {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;
}

.editBtns {
  display: flex;
  gap: 5px;
  width: 100%;
}

.editBtns .deleteBtn {
  background-color: red;
  display: flex;
  justify-content: center;
}

.editBtns .addEventBtn{
  background-color: #3b85ed;
  display: flex;
  justify-content: center;

} 

.editBtns .deleteBtn:hover {
  background-color: rgb(208, 0, 0);
  display: flex;
  justify-content: center;
}

.search-bar-container{
  display: flex;
  justify-content: center;
}

.searchBar {
  border: none;
  border-radius: 10px;
  height: 35px;
  text-align: center;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}


.selected-community-table .statsTable {
  margin: 0 auto;
  background-color: #f0f0f0;
  width: 100%;
  height: auto;
  border-spacing: 0;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.selected-community-table .statsTable th,
.selected-community-table .statsTable td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.selected-community-table .statsTable th {
  background-color: #3bce8e;
  border-right: 1px solid white;
  font-weight: bold;
  color: white;
}

.td-name{
  cursor: pointer;
}

.selected-community-table .statsTable tbody tr:hover {
  background-color: #f0f0f0;
}

.selected-community-table .statsTable .sortBtn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.statsTable .sortBtn i {
  font-size: 12px;
}

.pagination{
  margin: 0 auto;
  padding: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3bce8e;  
}

.pagination button{
  background-color: transparent;
  color: #3bce8e;
}

.user-select-list {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 400px;
  height: 500;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: scroll;
  transform: translate(-50%, -50%);
}

.users-list-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.users-list-title i {
  font-size: 26px;
  color: black;
  cursor: pointer;
}

.user-select-option-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.user-select-option-list label {
  font-size: 14px;
  font-weight: normal;
}

.user-select-option {
  display: flex;
  justify-content: space-between;
}

.custom-checkbox {
  position: relative;
  cursor: pointer;
}

.custom-checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border: 1.5px solid #3bce8e;
  border-radius: 4px;
  background: #fff;
}

.custom-checkbox:checked:before {
  background: #3bce8e;
  border-color: #3bce8e;
}

.custom-checkbox:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
}

.pop-up {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3bce8e;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  z-index: 1000;
}


.pop-up.hide {
  display: none;
}

.pop-up.error {
  background-color: #f44336;
}

