body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --primary: #3bce8e;
  --primary-faded: #30a673;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

#root {
  font-family: 'Poppins', sans-serif;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

}


a {
  font-weight: 500;
  color: #454bbd;
  text-decoration: inherit;
  text-decoration: underline;
  cursor: pointer;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;

  background-color: #f7f7f7;
  
}





button {
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: var(--primary);
  cursor: pointer;
  transition: 0.25s;
  color:white;
}
button:hover {
  background-color: var(--primary-faded);
}

button:active{
  transform: scale(0.9);
}

.inv-button, .inv-button2 {
  border-radius: 5px;
  border: 1px solid var(--primary);
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: white;
  cursor: pointer;
  transition: 0.25s;
  color:var(--primary);
}

.inv-button:hover {
  background-color: var(--primary);
  color: white;
}

.inv-button2:hover {
  background-color: whitesmoke;
  transform: scale(1.05);
  color:goldenrod
}


.center{
  text-align: center;
}

.left{
  text-align: left;
}

.right{
  text-align: right;
}

.set-background{

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}

.grid-2{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-2-gap{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:8px;
}


 ul li{
  list-style: inside;
  margin-left: 10px;
}

