* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Lato', sans-serif;
  line-height: 1;
}


.sidebar-navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 80px;
  background-color: #3BCE8E;
  float: left;
  transition: width 0.3s ease;
}

.sidebar-navigation.open {
  width: 300px;

}


.sidebar-navigation.open ul li span {
  color: white;    
  font-weight: bold;
  margin-top: 10px;
}

.sidebar-navigation.open ul li span:hover {
  color: #3BCE8E;
}

.sidebar-navigation ul {
  text-align: center;
  color: white;
  width: 100%;
  padding: 0;
}

.sidebar-navigation ul li {
  padding: 30px 0;
  cursor: pointer;
  list-style: none;
  transition: all ease-out 120ms;
  position: relative;
  width: 100%;
  margin: 0;
}

.sidebar-navigation ul li i {
  display: block;
  font-size: 18px;
  transition: all ease 450ms;
}

.sidebar-navigation ul li .tooltip {
  display: inline-block;
  position: absolute;
  background-color: #313443;
  padding: 8px 15px;
  border-radius: 3px;
  left: 90px;
  opacity: 0;
  visibility: hidden;
  font-size: 13px;
  letter-spacing: .5px;
  z-index: 100;
  transition: left 0.3s ease;
}

.sidebar-navigation ul li .tooltip:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 100;
  left: -4px;
  top: 10px;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: inherit;
}

.sidebar-navigation ul li:hover {
  background-color: #ffffff;
}

.sidebar-navigation ul li:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.sidebar-navigation ul li:hover i {
  color: #3BCE8E;
}

.sidebar-navigation ul li.active {
  background-color: #ffffff;
}

.sidebar-navigation ul li.active i {
  color: #3BCE8E;
}

.sidebar-navigation ul li.active span {
  color: #3BCE8E;
}

.sidebar-navigation.open ul li:hover span {
  color: #3BCE8E;
}  

.sidebar-navigation.open ul li .tooltip {
  left: 200px;
}

.menu-item-container {
  display: inline-block;    
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.menu-item-container .text {
  font-size: 14px; 
}