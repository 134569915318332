/* Styling for .communities-list */
.communities-list {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.communities-list::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../../img/bgSweep.webp) bottom center no-repeat;
  background-size: cover;
  opacity: 0.05;
  z-index: -1;
}

.communities-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  height: 100vh;
}

.communities-list-container h1 {
  color: rgb(59, 206, 142);
  text-align: left;
}

.communities-page-container {
  height: auto;/* Colocando a 100% a tabela fica com um tamanho fixo, assim varia com o nº de users */
  width: auto;
}

.community-options {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 70px 0 30px 0;
  height: auto;
}

.search-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.searchBar {
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 35px;
  text-align: center;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}

.communities-table {
  position: relative;
  height: 85%;
  margin-bottom: 100px;
}

.communities-table .statsTable {
  margin: 0 auto;
  background-color: #f0f0f0;
  border-spacing: 0;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: 1;
  table-layout: auto;
  width: 100%;
}

.communities-table .statsTable th,
.communities-table .statsTable td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.communities-table .statsTable th {
  background-color: #3bce8e;
  border-right: 1px solid white;
  font-weight: bold;
}

.communities-table .statsTable tbody tr:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}

.communities-table .statsTable .sortBtn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.statsTable .sortBtn i {
  font-size: 12px;
}

.td-name {
  cursor: pointer;
}


.report-image{
  width: 70px;
  height: 70px;
  object-fit: contain;
  transition: transform 0.5s;
}

.report-image:hover{
  transform: scale(4);
}
