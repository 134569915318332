.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .popup-content h2 {
    color: #3bce8e;
    margin-bottom: 10px;
  }
  
  .edit-form {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
  }
  
  .form-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .form-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap:10px;
  }
  
  .form-row label {
    flex: 1;
    font-weight: 600;
    font-size: 1rem;
  }
  
  .form-row input {
    flex: 2;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .popup-buttons {
    display: flex;
    justify-content: flex-end;
    justify-content: space-between;
  }
  
  .popup-buttons button {
    cursor: pointer;
  }
  

  .popup-photo{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 100px;
  }

  .add-photo{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    color: #3bce8e;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 100px;
    border: 1px solid #3bce8e;
  }

  .popup-upload{
    display: flex;
    flex-direction:row;
    place-items: center;
    gap:10px;
    padding:5px;
    color: #3bce8e;
  }

  .popup-upload img{
    border: 1px solid #3bce8e;
  }

  .popup-upload > input{
    display: none;    
  }

  .popup-selected{
    color: #3bce8e;
    background-color: white;
    cursor: pointer;
  }

  .popup-selected:hover{
    color: #38916a;
    background-color: #f0f0f0;
  }

.promote-select{
    padding: 5px;
    border-radius: 5px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    font-size: 16px;
    border: 1px solid lightgray;
  }

  .popup-buttons .deleteBtn {
    background-color: red;
    display: flex;
    justify-content: center;
  }
  
  .popup-buttons .deleteBtn:hover {
    background-color: rgb(208, 0, 0);
    display: flex;
    justify-content: center;
  }

.mfa-code{
  font-size: 32px;
  font-family: inherit;
  text-align: center;
}

.mfa-error{
  background-color: rgb(236, 86, 86);
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.errormsg{
  display: flex;
  justify-content: center;
  padding: 20px 0;
  color: red;
  text-align: center;
}