.map-container {
  height: 100vh;
  margin: 0 auto;
}

.homepage-map-container {
  margin: 0 auto 100px auto;
}

#map {
  border: 1px solid #3bce8e;
}

.homepage-map {
  height: 60%;
  padding: 20px 100px;
}

.homepage-map-container h1 {
  color: #3bce8e;
  text-align: center;
  padding: 40px 0;
}
